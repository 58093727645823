var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('Navbar',{attrs:{"navbarTitle":"Add Competitor","primaryBtnText":"Create"},on:{"navigate":_vm.navigateToManage,"primaryEvent":function($event){_vm.createPosition = true;
        _vm.submitCompetitor();}}})],1),_c('v-container',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-form',{ref:"form",staticClass:"mt-4 mb-4 ml-1-5"},[_c('v-text-field',{staticClass:"v-application Field-HeaderBold TextLeftBlackMediumBody1Light",attrs:{"rules":[function (v) { return !!v || 'Required'; }],"color":"Black"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v("Competitor Name ")]},proxy:true}]),model:{value:(_vm.CompetitorName),callback:function ($$v) {_vm.CompetitorName=$$v},expression:"CompetitorName"}}),_c('v-col',{attrs:{"cols":""}},[_c('v-autocomplete',{ref:"PCList",staticClass:"Chip-NameSide",attrs:{"items":_vm.PCList,"item-text":"Name","label":"Primary Category","multiple":"","clearable":"","return-object":""},on:{"change":function($event){return _vm.PrimaryCategoryChanged(_vm.categorySelection)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.RemovePCChip(index)}}},[_vm._v(" "+_vm._s(item.Name)+" ")])]}}]),model:{value:(_vm.categorySelection),callback:function ($$v) {_vm.categorySelection=$$v},expression:"categorySelection"}})],1)],1),_vm._l((_vm.categorySelection),function(ite){return _c('span',{key:ite.Id,attrs:{"index":_vm.index}},[[_c('span',{staticClass:"red--text"},[_vm._v("* ")]),_vm._v(" Add Products to "+_vm._s(ite.Name)+" ")],_c('v-combobox',{attrs:{"label":"Enter the products","multiple":"","small-chips":"","solo":"","clearable":""},on:{"input":function($event){return _vm.productmapping(_vm.index,_vm.products[_vm.index])}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var attrs = ref.attrs;
        var item = ref.item;
        var index = ref.index;
        var select = ref.select;
        var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeElement(ite,index)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}],null,true),model:{value:(ite.skuIds),callback:function ($$v) {_vm.$set(ite, "skuIds", $$v)},expression:"ite.skuIds"}})],2)})],2)],1),_c('Snackbar',{attrs:{"message":_vm.snackBarMessage,"snackbar":_vm.snackbar,"timeout":'500',"color":_vm.snackColor},on:{"update:snackbar":function($event){_vm.snackbar=$event},"closeSnackbar":_vm.closeSnackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }