














































































































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import Navbar from "@/components/common/Navbar.vue";
import { Progress } from "@/Enum/Progress";
import Snackbar from "@/components/common/Snackbar.vue";
import { ICompetitorInfo,prod } from "@/interfaces/CompetitorSurveyManagement";
import {EntityMin,EntityMinWithIds} from "@/interfaces/EntityMin"
import { Product } from "@/interfaces/CompetitorSurveyManagement";
import { PrimaryCategory} from "@/Enum/CompetitorSurveyManagement";
import CompetitorSurveyManagementService from "@/services/CompetitorSurveyManagementService";
import ProductService from "@/services/ProductService";
import router from '@/router/routes';

@Component({
  components: {
    Navbar,
    Snackbar,
  },
})
export default class Step1 extends Vue {
  @Prop() readonly asView!: boolean;
  @Prop() asEdit!: boolean;
  private blank: boolean = true;
  private categorySelection: Array<EntityMinWithIds> = [];
  private skuMappingModel:Array<EntityMinWithIds>=[];
  private index: Array<any> = [];
  private sku: Array<any> = [];

  public PCList: Array<EntityMin> = [];
  public PClistName:Array<any>=[];
  public products:Array<any>=[];
  
  

  private readonly CompetitorName: string = "";

  private primaryCategory: any = [
    { key: "Primary Category 1", value: PrimaryCategory.Primary_Category_one },
    { key: "Primary Category 1", value: PrimaryCategory.Primary_Category_two },
    {
      key: "Primary Category 1",
      value: PrimaryCategory.Primary_Category_three,
    },
    { key: "Primary Category 1", value: PrimaryCategory.Primary_Category_four },
  ];
  private CompetitorInfo: ICompetitorInfo = {
    CompetitorId: "",
    CompetitorName: "",
    PrimaryCategory: PrimaryCategory.Primary_Category_one,
    AddProductToPC: " ",
  };
  private basicCompetitor:Array<Product>=[]
  private pcmappings:Array<Product>=[]
  private model:Product = 
  {
    ProductId:0,
    ProductIds:[]
  };
  private isTrue:boolean=false;
  private pcId:number=0;
  productmapping(pcname:string,pIds:Array<string>){
 
    //this.iiidds=pIds;
      this.pcId=0;
      this.isTrue=false;
      this.PCList.forEach((element:any)=>{
        if(element.Name==pcname){
          //this.IIDD=element.Id
          this.pcId=element.Id
        }
      });
      if(this.pcmappings.length!=0){
        this.pcmappings.forEach((element:any) => {
          if(element.ProductId==this.pcId){
              element.ProductIds=[];
              element.ProductIds.push(pIds);
          }
          else{
              this.isTrue=true;
          }
        });
        if(this.isTrue){
          var item:Product={
            ProductId:0,
            ProductIds:[]
          }
          item.ProductId=this.pcId,
          item.ProductIds=pIds,
          
          this.pcmappings.push(item);

        }
      }
      else{
        this.isTrue=true;
          var item:Product={
            ProductId:0,
            ProductIds:[]
          }
          item.ProductId=this.pcId,
          item.ProductIds=pIds,
          this.pcmappings.push(item);

        }
      

  }
  private temp:number=0;
  private snackBarMessage: string = "";
  private snackbar: boolean = false;
  private snackColor: string="red";
  private ProductType4: any;
  private ProductType3: any;
  private ProductType2: any;
  private ProductType1: any;
  private pcData:any;
  private datamodel:prod={
    CompetitorName:"",
    products:[],
  }
  RemovePCChip(index:number){
    this.categorySelection.splice(index,1);
  }
  getAllPC() {
    let that = this;
    return new Promise<boolean>((res) => {
      ProductService.getPrimaryCategories()
        .then((response: any) => {
          that.PCList = [];
          that.PClistName=[];
          that.pcData=response.data
          response.data.forEach((element: any, index: number) => {
            var item:EntityMin={
                Id:0,
                Name:""
            }
            item.Id=element.id,
            item.Name=element.name,
            that.PCList.push(item);
            that.PClistName.push(element.name)

            res(true);
          });
        })
        .catch((err: any) => {
          console.log(err);
        });
    });
  }

  PrimaryCategoryChanged(item: any) {
    this.skuMappingModel=this.categorySelection;
    ProductService.getAllProduct(item)
      .then((res: any) => {
        //this.products = res.data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private productFetch(skuName: Array<string>) {
    let that = this;
    this.sku.push(skuName);
  }
  private primaryIds:Array<number>=[];

  public submitCompetitor() {
    this.datamodel.CompetitorName=this.CompetitorName;
    this.datamodel.products=this.categorySelection;
    if(this.CompetitorName.length==0){
          this.snackColor = "red";
          this.snackBarMessage = "Competitor Name is Required!";
          this.snackbar = true;
    }
    else if(this.categorySelection.length==0){
          this.snackColor = "red";
          this.snackBarMessage = "Primary Category is Required!";
          this.snackbar = true;
    }
    else{
      if (this.asEdit) {
        CompetitorSurveyManagementService.UpdateCompetitor(
          this.CompetitorInfo.CompetitorId,
          this.CompetitorName.trim()
        )
          .then((res: any) => {
            this.snackColor = "green";
            this.snackBarMessage = "Competitor Updated successfully.!!";
            this.snackbar = true;
            setTimeout(() => this.$router.go(0), 2000);
            return;
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        CompetitorSurveyManagementService.Add(

        this.datamodel
        )
          .then((res: any) => {
            this.snackColor="green";
            this.snackBarMessage = "Competitor Created successfully.!!";
            this.snackbar = true;
            setTimeout(
              () => this.$router.push("/CompetitorSurveyManagement/Create"),
              2000
            );
            return;
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }
  navigateToManage(){
    router.push('/competitorsurveymanagement/create')
  }
  private red:any;
  public pcIdtemp:number=0;

  removeElement(item: EntityMinWithIds, index:number) {
    this.pcIdtemp=item.Id;
    this.categorySelection.forEach((element:EntityMinWithIds)=>{
        if(element.Id==this.pcIdtemp){
          element.skuIds.splice(index,1);
        }
    })
    // this.products.splice(this.products.indexOf(item), 1);
    // this.products = [...this.products];
    // this.itemList.splice(index, 1);
  }

  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }
  mounted() {
    this.getAllPC();
  }
}
